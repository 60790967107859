<template>
  <div class="program text-center">
    <img src="../assets/afis-website.jpg" alt="" />
<!--    <h1>-->
<!--      {{ c.title }}-->
<!--    </h1>-->
<!--    <p class="m-t-8 m-b-24">-->
<!--      {{ c.date }}-->
<!--    </p>-->
<!--    <ul>-->
<!--      <li v-for="({date, bands}, index) in c.days"-->
<!--          :key="index">-->
<!--        <b>-->
<!--          {{ date }}-->
<!--        </b>-->
<!--        <p class="m-t-8 m-b-24"-->
<!--           v-html="bands" />-->
<!--      </li>-->
<!--    </ul>-->

<!--    <Audio />-->
  </div>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import Audio from "@/components/Audio.vue";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('program'))
</script>

<style lang="scss">
  .program {
    @media only screen and (min-width: 0) {
      padding-top: 24px;
      //padding-bottom: 80px;
      //background-image: url("../assets/bg-pattern-3.webp");
      background-size: 100%;
      background-repeat: repeat-x;
      background-position: top;
      background-color: rgba(255, 255, 255, 0.8); /* Black with 50% opacity */
      background-blend-mode: overlay;

      img {
        width: 100%;
      }

      h1 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    @media only screen and (min-width: 1024px) {
      background-position: center;
    }
  }
</style>